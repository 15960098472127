import { setLocalStorageDrivers, setSessionStorageDrivers, memoryStorage } from "./common-ui/helpers/storage";

let storageInitialized = false;

export const initializeStorage = () => {
    if (storageInitialized) {
        return;
    }
    storageInitialized = true;
    setLocalStorageDrivers([window.localStorage, memoryStorage]);
    setSessionStorageDrivers([window.sessionStorage, memoryStorage]);
};
