import React, { useEffect } from "react";
import { SuspenseLoadingScreen } from "./common-ui/components/Utils/LoadingScreen";
import styled, { StyleSheetManager } from "styled-components";
import { initializeStorage } from "./storage";

import "normalize.css/normalize.css";

initializeStorage();

const App = React.lazy(() => import("./App"));

const AwaitingBlueprintStyles = styled.div`
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 1.28581;
    text-transform: none;
    color: #182026;
    font-family: -apple-system, "BlinkMacSystemFont", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Open Sans",
        "Helvetica Neue", "Icons16", sans-serif;

    height: 100vh;
`;

const AppLoader: React.FC = () => {
    // Preload authentication in parallel with <App />
    useEffect(() => {
        (async () => {
            try {
                const { sharedAuthentication } = await import("./components/Authentication/authentication");
                await sharedAuthentication.getValidSession();
            } catch {}
        })();
    }, []);

    return (
        <React.Suspense
            fallback={
                <AwaitingBlueprintStyles>
                    <SuspenseLoadingScreen />
                </AwaitingBlueprintStyles>
            }
        >
            {/*
             * All CSS styles that we write, that might have required
             * CSS vendor prefixing in the past, are supported natively in
             * the modern browsers that we support (see browser.ts / caniuse.com)
             */}
            <StyleSheetManager disableVendorPrefixes={false}>
                <App />
            </StyleSheetManager>
        </React.Suspense>
    );
};

export default AppLoader;
