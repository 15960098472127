/* eslint-disable max-classes-per-file */
import { useEffect, useState, Dispatch, SetStateAction } from "react";

export interface IAppStorage {
    setItem: (key: string, value: string) => void;
    getItem: (key: string) => string | null | undefined;
    removeItem: (key: string) => void;
    clear(): void;
}

class MemoryStorage implements IAppStorage {
    private listStorage: Map<string, string> = new Map();

    public setItem(key: string, value: string) {
        this.listStorage.set(key, value);
    }

    public getItem(key: string): string | null | undefined {
        return this.listStorage.get(key);
    }

    public removeItem(key: string) {
        this.listStorage.delete(key);
    }

    public clear() {
        this.listStorage.clear();
    }
}

export const memoryStorage = new MemoryStorage();

function getItem(key: string, drivers: IAppStorage[]): string | undefined {
    for (let i = 0; i < drivers.length; i += 1) {
        try {
            const value = drivers[i].getItem(key);
            if (value) {
                return value;
            }
        } catch (ex) {
            if (i === drivers.length - 1) {
                throw ex;
            }
        }
    }
    return undefined;
}

function setItem(key: string, value: string, drivers: IAppStorage[]) {
    for (let i = 0; i < drivers.length; i += 1) {
        try {
            drivers[i].setItem(key, value);
            break;
        } catch (ex) {
            if (i === drivers.length - 1) {
                throw ex;
            }
        }
    }
}

function removeItem(key: string, drivers: IAppStorage[]) {
    for (const driver of drivers) {
        try {
            driver.removeItem(key);
        } catch (ex) {
            //
        }
    }
}

function clear(drivers: IAppStorage[]) {
    for (const driver of drivers) {
        try {
            driver.clear();
        } catch (ex) {
            //
        }
    }
}

export enum StorageType {
    Local,
    Session,
}

let localStorageDrivers: IAppStorage[] = [memoryStorage];
let sessionStorageDrivers: IAppStorage[] = [memoryStorage];

export const setLocalStorageDrivers = (drivers: IAppStorage[]) => {
    localStorageDrivers = drivers;
};
export const setSessionStorageDrivers = (drivers: IAppStorage[]) => {
    sessionStorageDrivers = drivers;
};

function driversForStorageType(storageType: StorageType): IAppStorage[] {
    switch (storageType) {
        case StorageType.Local:
        default:
            return localStorageDrivers;
        case StorageType.Session:
            return sessionStorageDrivers;
    }
}

class AppStorage {
    // eslint-disable-next-line class-methods-use-this
    public setItem(key: string, value: string, storageType: StorageType) {
        setItem(key, value, driversForStorageType(storageType));
    }

    // eslint-disable-next-line class-methods-use-this
    public getItem(key: string, storageType: StorageType): string | null | undefined {
        return getItem(key, driversForStorageType(storageType));
    }

    // eslint-disable-next-line class-methods-use-this
    public removeItem(key: string, storageType: StorageType) {
        removeItem(key, driversForStorageType(storageType));
    }

    // eslint-disable-next-line class-methods-use-this
    public clear(storageType: StorageType) {
        clear(driversForStorageType(storageType));
    }
}

export const appStorage = new AppStorage();

export const localStorageFallback: IAppStorage = {
    setItem: (key: string, value: string) => appStorage.setItem(key, value, StorageType.Local),
    getItem: (key: string) => appStorage.getItem(key, StorageType.Local),
    removeItem: (key: string) => appStorage.removeItem(key, StorageType.Local),
    clear: () => appStorage.clear(StorageType.Local),
};

export const sessionStorageFallback: IAppStorage = {
    setItem: (key: string, value: string) => appStorage.setItem(key, value, StorageType.Session),
    getItem: (key: string) => appStorage.getItem(key, StorageType.Session),
    removeItem: (key: string) => appStorage.removeItem(key, StorageType.Session),
    clear: () => appStorage.clear(StorageType.Session),
};

// eslint-disable-next-line @typescript-eslint/ban-types
export const STATE_DESERIALIZER_PLAIN = <T extends {}>(rawValue: string) => (rawValue as unknown) as T | undefined;
// eslint-disable-next-line @typescript-eslint/ban-types
export const STATE_SERIALIZER_PLAIN = <T extends {}>(value: T | undefined) => (value as unknown) as string | undefined;

export const STATE_DESERIALIZER_NUMBER = (rawValue: string) => {
    if (rawValue != null && rawValue !== "") {
        const numberValue = Number(rawValue);
        if (!Number.isNaN(numberValue)) {
            return numberValue;
        }
    }
    return undefined;
};
export const STATE_SERIALIZER_NUMBER = (value: number | undefined) => value?.toString();

// eslint-disable-next-line @typescript-eslint/ban-types
export const STATE_DESERIALIZER_JSON = <T extends {}>(rawValue: string) =>
    rawValue.length > 0 ? (JSON.parse(rawValue) as T) : undefined;
// eslint-disable-next-line @typescript-eslint/ban-types
export const STATE_SERIALIZER_JSON = <T extends {}>(value: T | undefined) =>
    value != null ? JSON.stringify(value) : undefined;

const callbacksPerKey: Record<string, Array<(value: unknown) => void>> = {};

// eslint-disable-next-line @typescript-eslint/ban-types
export function usePersistedState<T extends {} | undefined>(
    storageKey: string,
    initialValue: T | (() => T),
    options?: {
        storageType?: StorageType;
        deserialize?: (rawValue: string) => NonNullable<T> | undefined;
        serialize?: (value: NonNullable<T> | undefined) => string | undefined;
    }
): [T, Dispatch<SetStateAction<T>>] {
    const storageType = options?.storageType || StorageType.Local;
    const deserialize = options?.deserialize || STATE_DESERIALIZER_PLAIN;
    const serialize = options?.serialize || STATE_SERIALIZER_PLAIN;

    const calculateInitialValue = (): T => {
        if (typeof initialValue === "function") {
            return (initialValue as () => T)();
        } else {
            return initialValue as T;
        }
    };
    const loadStoredOrInitial = (): T => {
        const rawValue = appStorage.getItem(storageKey, storageType);
        if (rawValue != null) {
            try {
                const deserializedValue = deserialize(rawValue);
                if (deserializedValue != null) {
                    return deserializedValue;
                }
            } catch {
                // use initial value when deserialization fails
            }
        }
        return calculateInitialValue();
    };
    const [value, setValue] = useState<T>(loadStoredOrInitial);
    useEffect(() => {
        const serializedValue = serialize(value ?? undefined);
        if (serializedValue != null) {
            appStorage.setItem(storageKey, serializedValue, storageType);
        } else {
            appStorage.removeItem(storageKey, storageType);
        }
    }, [value, storageKey, serialize, storageType]);

    // notify other instances of the same (key) hook of any changes
    useEffect(() => {
        const valueChangeCallback = (v: unknown) => {
            setValue(v as T);
        };
        if (callbacksPerKey[storageKey] == null) {
            callbacksPerKey[storageKey] = [];
        }
        for (const otherWatcher of callbacksPerKey[storageKey]) {
            otherWatcher(value);
        }
        callbacksPerKey[storageKey].push(valueChangeCallback);
        return () => {
            callbacksPerKey[storageKey] = callbacksPerKey[storageKey].filter(w => w !== valueChangeCallback);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [storageKey, value, setValue]);

    return [value, setValue];
}

export function usePersistedStateNumber(
    storageKey: string,
    initialValue: number | (() => number),
    options?: {
        storageType?: StorageType;
    }
): [number, Dispatch<SetStateAction<number>>] {
    return usePersistedState<number>(storageKey, initialValue, {
        deserialize: STATE_DESERIALIZER_NUMBER,
        serialize: STATE_SERIALIZER_NUMBER,
        ...options,
    });
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function usePersistedStateJson<T extends {} | undefined>(
    storageKey: string,
    initialValue: T | (() => T),
    options?: {
        storageType?: StorageType;
    }
): [T, Dispatch<SetStateAction<T>>] {
    return usePersistedState<T>(storageKey, initialValue, {
        deserialize: STATE_DESERIALIZER_JSON,
        serialize: STATE_SERIALIZER_JSON,
        ...options,
    });
}

export function usePersistedStateBoolean(
    storageKey: string,
    initialValue: boolean | (() => boolean),
    options?: {
        storageType?: StorageType;
    }
): [boolean, Dispatch<SetStateAction<boolean>>] {
    return usePersistedState<boolean>(storageKey, initialValue, {
        deserialize: rawValue => (rawValue != null ? rawValue === "true" : undefined),
        // eslint-disable-next-line no-nested-ternary
        serialize: value => (value != null ? (value ? "true" : "false") : undefined),
        ...options,
    });
}
