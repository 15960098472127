import React from "react";
import styled from "styled-components";

import "loaders.css/loaders.css";

interface ILoadingScreenProps {
    title?: string;
    color?: string;
    height?: string;
}

export const LOADING_TITLE_DEFAULT = "Loading…";
export const LOADING_TITLE_APP = "Loading App…";
export const LOADING_TITLE_DATA = "Loading Data…";

export const COLOR_DEFAULT = "#71727c";

const LoaderContainer = styled.div<{
    height?: string;
}>`
    width: 100%;
    height: ${props => props.height ?? "100%"};
    min-height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const LoadingRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;

const ChildrenRow = styled.div`
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const Loader = styled.div`
    & > div {
        background-color: ${props => props.color} !important;
    }
`;

const Text = styled.div`
    height: 23px;
    font-weight: bold;
    color: ${props => props.color};
    padding-left: 15px;
`;

export const LoadingScreen: React.FC<ILoadingScreenProps> = props => {
    const color = props.color || COLOR_DEFAULT;
    return (
        <LoaderContainer height={props.height}>
            <LoadingRow>
                <Loader color={color} className="ball-pulse">
                    <div />
                    <div />
                    <div />
                </Loader>
                <Text color={color}>{props.title || LOADING_TITLE_DEFAULT}</Text>
            </LoadingRow>

            {props.children && <ChildrenRow>{props.children}</ChildrenRow>}
        </LoaderContainer>
    );
};

export const SuspenseLoadingScreen = () => <LoadingScreen title={LOADING_TITLE_APP} />;

export const DataLoadingScreen = () => <LoadingScreen title={LOADING_TITLE_DATA} />;
